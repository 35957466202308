<template>
  <div>
<el-dialog
  title="更多物流"
  :visible.sync="MoreShow"
  width="725px"
  :before-close="handleClose">
 <el-row class="list_title">
     <el-col :span="9" class="pd20">物流名称</el-col>
     <el-col span="3">运费</el-col>
     <el-col :span="4">服务费</el-col>
     <el-col  :span="4" class="center">物流时间</el-col>
     <el-col  class="center" :span="4">备注</el-col>
 </el-row>
 <div class="body_box">
  <el-row class="list_body" v-for="item in 9" :key="item">
     <el-col :span="9">
         <el-checkbox></el-checkbox>
         <span class="name">顺丰国际小包挂号</span>
     </el-col>
     <el-col :span="3">¥58.33</el-col>
     <el-col :span="4">-</el-col>
     <el-col :span="4" class="center">10-15工作日</el-col>
     <el-col :span="4" class="center">2.9折</el-col>
 </el-row>
 </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleClose">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  props:{
      MoreShow:{
          type:Boolean
      }
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClose() {
        this.$emit('more',false)
      }
  }
}
</script>

<style scoped lang="less">
.center{
    text-align: center;
}
.pd20{
    padding-left: 20px;
}
.list_title{
    padding:10px 0 10px 20px;
    font-size: 15px;
    background: #F7F7F7;
    color: #333;
}
.body_box{
    height: 255px;
    overflow-x: hidden;
  .list_body{
  color: #333;
  padding: 10px 0 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #d8d8d8;
  .name{
      margin-left: 10px;
  }
}
}
</style>
