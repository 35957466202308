<template>

  <div class="pop_div">
    <div class="divform"></div>
    <el-dialog width="600px" title="订单支付" :visible.sync="PayPopShow" :before-close="handleClose">

      <div class="tabs_nav">
        <div class="tab_item"
             @click="handleChangeCurrentPayMethod(1)"
             :class="{'tab_item_active':currentSelectPayMethod === 1}">
          <span class="tab_item_text">余额支付</span>
          <span class="line"></span>
        </div>
        <div class="tab_item"
             @click="handleChangeCurrentPayMethod(2)"
             :class="{'tab_item_active':currentSelectPayMethod === 2}">
          <span class="tab_item_text">支付宝</span>
          <span class="line"></span>
        </div>
      </div>

      <div v-if="currentSelectPayMethod === 1">
        <div class="content_">
          <div class="row_ mb20">
            <span class="lab">支付金额</span>
            <span class="price">￥ {{ amount }}</span>
          </div>
          <div class="row_">
            <span class="lab">余额</span>
            <span class="price">￥{{ balance }}</span>
          </div>
          <div class="err-msg" v-if="num">余额不足，请先充值再支付！</div>
        </div>


        <div class="bottom_" v-if="num">
          <el-button class="bt" @click="recharge">充值</el-button>
        </div>
        <div class="bottom_" v-else>
          <el-button class="bt" @click="payYue" :loading="lad">立即支付</el-button>
        </div>
      </div>

      <div v-if="currentSelectPayMethod === 2">
        <div class="content_">
          <div class="row_ mb20">
            <span class="lab">支付金额</span>
            <span class="price">￥ {{ amount }}</span>
          </div>
        </div>
        <div class="bottom_">
          <el-button class="bt" @click="alipay" :loading="lad">立即支付</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {orderPayByAlipay, orderPayByYue} from '@/api/order'

export default {
  props: {
    PayPopShow: {
      type: Boolean
    },
    amount: {
      type: String,
      default: '0.00'
    },
    balance: {
      type: String,
      default: '0.00'
    },
    orderNumbers: {
      type: String
    }
  },
  data() {
    return {
      //当前选择的支付方式 1=余额 2=支付宝
      currentSelectPayMethod: 1,
      lad: false,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

    //充值
    recharge() {
      this.$router.push({name: 'expenseManagement', query: {flag: '1'}})
    },
    handleChangeCurrentPayMethod(val) {
      this.currentSelectPayMethod = val
    },
    handleClose() {
      this.$emit('payShow', false)

    },
    // 支付宝支付
    async alipay() {
      try {
        this.lad = true
        const {
          data:
              {
                info
              }
        } = await orderPayByYue(
            {
              orderNumbers: this.orderNumbers,
              channel: 'alipayweb'
            }
        )
        this.info = info
        let divForm = document.getElementsByTagName('divform')
        if (divForm.length) {
          document.body.removeChild(divForm[0])
        }
        const div = document.createElement('divform')
        div.innerHTML = this.info // res.data就是sb支付宝返回给你的form
        document.body.appendChild(div)
        // document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
        document.getElementById('alipay_submit').submit()
      } catch (e) {
        this.$message.error(e);
      }
      this.lad = false
    },
    //  余额支付
    async payYue() {
      this.lad = true
      try {
        const {message} = await orderPayByYue(
            {
              orderNumbers: this.orderNumbers,
              channel: 'balance'
            }
        )
        this.$message({
          message,
          type: 'success'
        });
        this.handleClose()
        this.$emit('update')
      } catch (e) {
        this.$message.error(e);
      }
      this.lad = false
    }
  },
  computed: {
    num() {
      return parseFloat(this.amount) > parseFloat(this.balance)
    }
  }
}
</script>

<style scoped lang="less">
.pop_div {
  border-radius: 10px;

  .tabs_nav {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    .tab_item {
      position: relative;
      margin-right: 30px;
      cursor: pointer;

      .tab_item_text {
        color: #333;
        font-size: 16px;
        font-weight: 400;
      }

      .line {
        display: none;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #FA4A14;
        left: 0;
        bottom: -11px;
      }

    }

    .tab_item_active {
      .tab_item_text {
        color: #FA4A14 !important;
      }

      .line {
        display: block !important;
      }
    }

  }

  .content_ {
    height: 156px;
    border-bottom: 1px solid #EEEEEE;

    .mb20 {
      margin-bottom: 20px;
    }

    padding-left: 20px;
    padding-top: 20px;

    .row_ {
      display: flex;
      justify-content: space-between;

      .lab {
        font-size: 14px;
        color: #333;
        font-weight: 400;
      }

      .price {
        font-size: 14px;
        color: #FD6969;
        font-weight: 400;
      }
    }
  }

  .err-msg {
    padding-top: 50px;
    // padding-left: 20px;
    color: #FD6969;
    font-size: 12px;
    font-weight: 400;
  }

  .bottom_ {
    margin-top: 30px;
    padding-left: 20px;


    .bt {
      border-radius: 2px !important;
      background-color: #FA4A14;
      margin-left: 430px;
      // width: 64px;
      height: 32px;
      border: none;
      font-size: 16px;
      color: white;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .msg {
    margin-top: 20px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;

    .pri {
      color: #FD6969;
    }
  }

  .qr_code_view {
    display: flex;
    justify-content: center;

    .qr_code {
      margin-top: 30px;
      width: 141px;
      height: 141px;
    }
  }

}
</style>
