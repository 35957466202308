import request from "@/utils/request";
const prefix = '/site'
//获取国家列表
// export const countryList = (
//   callback
// ) => {
//
//     return request(`${prefix}/countryList`, { params:{},method: 'get'}, callback)
// }
export const countryList = (
    callback
) => {

    return request('/base/worldArea', { params:{},method: 'get'}, callback)
}
// 获取用户常用地址
export const userAddressList = (
  callback
) => {

    return request(`${prefix}/userAddressList`, { params:{},method: 'get'}, callback)
}
// 添加用户常用地址
export const addUserAddress = (
   data,
  callback
) => {

    return request(`${prefix}/addUserAddress`, { data,method: 'post'}, callback)
}
// 删除用户常用地址
export const deleteUserAddress = (
    {
        addressId
    },
  callback
) => {
    const data = {
        addressId
    }
    return request(`${prefix}/deleteUserAddress`, { params:data,method: 'delete'}, callback)
}

//根据商品数量获取产品最终价格
export const finalProductPrice = (
    {
        productId,
        sizeId,
        quantity
    },
    callback
) => {
    const params = {
        productId,
        sizeId,
        quantity
    }
    return request(`/order/finalProductPrice`, { params,method: 'get'}, callback)
}