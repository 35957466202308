<template>
  <div>
<el-dialog
  title="运费试算"
  :visible.sync="trialShow"
  width="800px"
  :before-close="handleClose">
  <el-form :inline="true" :model="formInline" :rules="rules" class="demo-form-inline" size="mini">
    <el-form-item label="邮寄国家" prop="country">
    <el-select class="select_country" v-model="formInline.country" placeholder="请选择国家">
      <el-option 
      v-for="(item, index) in countrylist"
      :key="index"
      :label="item.cnname" :value="item.code"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="重量" prop="weight">
    <!-- <el-input class="inp_weight" v-model="formInline.weight" placeholder="请输入重量"></el-input> -->
    <el-select class="inp_weight" v-model="formInline.weight" placeholder="请输入重量">
      <el-option 
      v-for="(item, index) in weightlist"
      :key="index"
      :label="item.label" :value="item.val"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">计算</el-button>
  </el-form-item>
</el-form>
<div class="list">
<el-row class="title">
  <el-col :span="11"><div class="title_item">物流方式</div></el-col>
  <el-col :span="10"><div class="title_item">物流代码</div></el-col>
  <el-col :span="3"><div class="title_item">价格</div></el-col>
</el-row>
<el-row class="body" v-for="(item1, index1) in trialList" :key="index1">
  <el-col :span="11"><div class="body_item">{{item1.transport_name_cn}}</div></el-col>
  <el-col :span="10"><div class="body_item">{{item1.transport_code}}</div></el-col>
  <el-col :span="3"><div class="body_item">￥{{item1.price}}</div></el-col>
</el-row>
</div>
</el-dialog>
  </div>
</template>

<script>
import { countryList } from '@/api/site'
import { getLogisticsWithPriceList } from '@/api/logistics'
export default {
    props:{
        trialShow:{
            type:Boolean
        }
    },
  data () {
    return {
        countrylist:[],   //国家列表
        weightlist:[
            {'label':'100g','val':100},
            {'label':'500g','val':500},
            {'label':'1000g','val':1000},
            {'label':'1500g','val':1500},
            {'label':'3kg','val':3000},
            {'label':'5kg','val':5000},
            {'label':'10kg','val':10000},
            {'label':'50kg','val':50000},
        ],  //重量列表
          formInline: {
          country: '',
          weight: null
        },
        rules:{
            country:[
                { required: true, message: '请选择国家', trigger: 'blur' },
            ],
            weight:[
                { required: true, message: '请输入重量', trigger: 'blur' },
            ],
        },
        trialList:[],   //试算列表
    }
  },
  created () {
      // this.countryList()
  },
  mounted () {

  },
  methods: {
      handleClose(){
          this.$emit('trialS',false)
          this.formInline = {}
          this.trialList=[]
      },
    //   国家列表
      async countryList(){
          try{
              const { data, msg } = await countryList()
              this.countrylist = data
          } catch(e){
              console.log(e);
          }
      },
    //   计算
  async onSubmit(){
      if(this.formInline.country && this.formInline.weight){
        //factoryCode写死参数后期更改
        try{
            const { data, msg } =  await getLogisticsWithPriceList(
                {
                  country:this.formInline.country,
                  weight:this.formInline.weight,
                  factoryCode:'202108281112471114780'
                }
            )
            this.trialList = data.list
        } catch(e){
        this.$message({
          message: e.msg,
          type: 'warning'
        });
        }
      } else {
          this.$message({
          message: '必选信息未选择',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
    // height: 571px;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px 4px rgba(51, 51, 51, 0.3);
    border-radius: 10px;
    padding: 0 20px;
    .el-dialog__header{
        // margin: 0 20px;
        padding-left:0;
        padding-right: 0;
        border-bottom: 1px solid #d8d8d8;
    }
    .el-dialog__body{
        padding: 20px 10px;
    }
}
.select_country{
    width: 220px;
    margin-right: 40px;
}
.inp_weight{
    width: 220px;
    margin-right: 40px;
}
.list{
    .title{
        padding: 10px;
        background: #F7F7F7;
        color: #333;
    }
    .body{
        padding: 10px;
        // background: #F7F7F7;
        color: #333;
        border-bottom: 1px solid #d8d8d8;
        &:hover{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        }
    }
}
</style>
