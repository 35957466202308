<template>
<div>
<el-dialog
  title="编辑物流"
  :visible.sync="logisticsShow"
  width="725px"
  :before-close="handleClose">
  <el-alert
    title="系统会根据变更后的物流重新计算订单的运费，已支付订单可能会涉及到多退少补"
    type="warning"
      center
    show-icon>
  </el-alert>
  <el-row class="list_title">
     <el-col :span="9" class="pd20">物流名称</el-col>
     <el-col :span="3">运费</el-col>
     <el-col :span="4" class="center">服务费</el-col>
     <el-col  :span="4" >物流时间</el-col>
     <el-col  class="center" :span="4">备注</el-col>
 </el-row>
 <div class="body_box">
  <el-row class="list_body" v-for="item in 9" :key="item">
     <el-col :span="9">
         <el-checkbox></el-checkbox>
         <span class="name">顺丰国际小包挂号</span>
     </el-col>
     <el-col :span="3">¥58.33</el-col>
     <el-col :span="4" class="center">-</el-col>
     <el-col :span="4" class="center">10-15工作日</el-col>
     <el-col :span="4" class="center">2.9折</el-col>
 </el-row>
 </div>
  <div slot="footer" class="dialog-footer">
    <div class="footer_left">
        <span>新物流</span>
        <span class="color">顺丰国际小包挂号</span>
        <span>补差价</span>
        <span class="color">¥28</span>
    </div>
    <div class="footer_rigth">
    <el-button @click="handleClose">返回</el-button>
    <el-button type="primary" @click="handleClose">确 定</el-button>
    </div>
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
    props:{
        logisticsShow:{
            type:Boolean
        }
    },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClose() {
     this.$emit('logistics',false)
     }
  }
}
</script>

<style scoped lang="less">
.color{
    color: #FA4A14;
    margin-right: 20px ;
}
.center{
    text-align: center;
}
.pd20{
    padding-left: 20px;
}
/deep/.el-dialog{
background: #FFFFFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
border-radius: 10px;
}
/deep/.el-dialog__header{
    padding: 20px 0 17px;
    margin: 0 20px;
    border-bottom: 1px solid #d8d8d8;
    font-size: 18px;
}
/deep/.el-dialog__body{
    padding: 10px 20px 30px;
}
.el-alert{
    margin-bottom: 20px;
}
.list_title{
    padding:10px 0 10px 20px;
    font-size: 15px;
    background: #F7F7F7;
    color: #333;
}
.body_box{
    height: 255px;
    overflow-x: hidden;
    border-bottom: 1px solid #d8d8d8;
  .list_body{
  color: #333;
  padding: 10px 0 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #d8d8d8;
  .name{
      margin-left: 10px;
  }
}
}
.dialog-footer{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    span{
        margin-right: 10px;
    }
}
</style>
