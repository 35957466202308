<template>
<div>
  <el-dialog
      top="10%"
      custom-class="importOrderDialog"
      @open="open"
      title="导入订单"
      :visible.sync="importStoreOrdersShow"
      :before-close="handleClose">
    <div
        v-loading="!loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#fff"
    >
      <div class="border"></div>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-form-item label="请选择导入模版" prop="template">
          <el-radio-group v-model="form.template" @change="radioChange">
            <el-radio :label="item.platform_code" v-for="item in templateList">{{item.name}}</el-radio>

          </el-radio-group>
        </el-form-item>
        <el-form-item label="请选择导入店铺" prop="store">
          <el-radio-group v-model="form.store">
            <el-radio v-for="item in filterShop" :key="item.id" :label="item.code">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="explanation_box">
        <div class="ml-b">1. 上传文件格式：.xls，.xlsx</div>
        <div class="ml-b">2. (.txt)文件上传方式：双击打开 txt 文件 -> 全选（Ctrl + A）-> 复制（Ctrl + C）-> 新建一个Excel表格 -> 粘贴（Ctrl +V）至表格中 -> 保存（Ctrl + S）即可；</div>
        <div >3. 亚马逊订单下载操作步骤：  </div>
        <div style="margin-left: 5px">（1). 登录亚马逊店铺；</div>
        <div style="margin-left: 5px">（2). 选择“订单（Orders）” > “订单报告（Order Reports）” > 导出“新订单（New Orders）”或“未发货订单
          <div style="margin-left: 15px">（UnshippedOrders）”；</div></div>
        <div class="ml-b" style="margin-left: 5px">（3). 点击“导入订单文件”按钮，商城Amazon订单。</div>
        <div class="note">注意：建议从Amazon平台导出文件后不要编辑，避免文件数据错乱。</div>


      </div>
      <span slot="footer" class="dialog-footer">

      <el-button @click="handleClose">取 消</el-button>

      <el-upload
          class="upload-demo"
          ref="upload"
          action="/proxy/v1/order/importOrderFile"
          :limit="1"
          :show-file-list="false"
          :on-success="onSuccess"
          :on-error="onError"
          :headers="headers"
          :data="uploadIconData"
          :before-upload="beforeUpload"
          accept=".xls,.xlsx"
      >
          <el-button type="primary" class="primary">导入文件</el-button>
      </el-upload>
  </span>
    </div>
  </el-dialog>
  <!--    导入订单-->
  <import-order
      :importOrderShow="importOrderShow"
      @close="close"
      :orderNumber="orderNumber"
      :file="file"
      :template="form.template"
      :orderStatusList="orderStatusList"
      :store="form.store"
      :shopList="shopList"
  />

</div>
</template>

<script>
import {shopSelect} from "@/api/productTemplate";
import ImportOrder from "@/views/order/orderManagement/components/importOrder";
import Cookies from "js-cookie";

export default {
  components: {ImportOrder},
  props: {
    importStoreOrdersShow: {
      type: Boolean,
      default: false
    }
  },
  name: "importStoreOrders",
  data: () => ({
    loading:false,
    templateList:[
      {
        name:'亚马逊',
        platform_code: "amazon"
      },
      // {
      //   name:'shopee',
      //   platform_code: "shopee"
      // }
    ],
    importOrderShow:false,
    filterShop:[],
    form: {
      template: 'amazon',//模板
      store: '',//店铺
    },
    rules: {
      template: [{required: true, message: '请选择导入模版', trigger: 'blur'}],
      store: [{required: true, message: '请选择导入店', trigger: 'blur'}]
    },
    shopList: [], //店铺
    orderStatusList:{},//订单状态
    file:{},
    orderNumber:[]
  }),
  mounted() {

  },
  computed: {
    // 请求头
    headers() {
      let token
      if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
        token = process.env.VUE_APP_TOKEN
      } else {
        token = Cookies.get('token')
      }
      return {

        'Authorization': `Bearer ${token}`,
      }
    },

    contentType() {

    },
    // 参数
    uploadIconData() {
      return {
        storeCode: this.form.store,
        type: this.form.template,
      }
    }

  },
  methods: {
    //导入订单弹窗
    close(){
      this.importOrderShow = false
      this.orderNumber = []
    },

    // 上传前
    beforeUpload(file){
      this.file = file
    },

    //上传文件失败
    onError(err, file, fileList) {
      const response = JSON.parse(err.message)

      this.$message.error(response.msg || '上传文件失败')
    },

    //成功回调
    onSuccess(response, file, fileList) {
      if(response.code === 200) {
        this.orderStatusList = response?.data
        this.orderStatusList?.list?.repeatOrder?.orders?.forEach(item => {
          this.orderNumber.push({'val': item, 'message': this.orderStatusList?.list?.repeatOrder?.message})
        })
        this.orderStatusList?.list?.unMatchStatusOrder?.orders?.forEach(item => {
          this.orderNumber.push({'val': item, 'message': this.orderStatusList?.list?.unMatchStatusOrder?.message})
        })
        this.orderStatusList?.list?.unSystemSkuOrder?.orders?.forEach(item => {
          this.orderNumber.push({'val': item, 'message': this.orderStatusList?.list?.unSystemSkuOrder?.message})
        })
        this.orderStatusList?.list?.alreadyInsertOrder?.orders?.forEach(item => {
          this.orderNumber.push({'val': item, 'message': this.orderStatusList?.list?.alreadyInsertOrder?.message})
        })
        this.$refs.upload.clearFiles();
        this.handleClose()
        this.importOrderShow = true
      }
    },

    // 获取店铺
    async shopSelect() {
      this.loading = false
      try {
        const data = await shopSelect()
        this.shopList = data?.data
      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
      this.loading = true
    },

    // 关闭弹窗
    handleClose() {
      this.$emit('close', false)
      this.filterShop = []
    },

    //弹窗打开时
    async open() {
      await this.shopSelect()
      this.form.template = 'amazon'
      this.filterShop = this.shopList.filter(v=> v.platform_code === this.form.template)
      if (this.filterShop.length) {
        this.form.store = this.filterShop[0].code
      }
    },

    //模板改变时
    radioChange(e){
      this.filterShop = this.shopList.filter(v=> v.platform_code === e)
      this.form.store = this.filterShop[0].code
    }

  },
  watch: {},

}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  width: 800px;
  //height: 657px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}
.note {
  font-weight: 600;
  color: #333;
  line-height: 20px;
}
.border {
  width: 760px;
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 10px;
}

/deep/ .el-form-item {
  margin-bottom: 0;
}

.explanation_box {
  width: 720px;
  height: 285px;
  background: #F7F7F7;
  border: 1px dashed #D8D8D8;
  margin-left: 20px;
  padding: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  line-height: 20px;
}

.mt-1 {
  margin-top: 20px;
}

.primary {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
}

.inputFile {
  display: none;
}

/deep/ .el-dialog__footer {
  padding-top: 0;
}

.dialog-footer {
  margin-top: 20px;
  padding: 0 20px !important;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  .upload-demo {
    display: flex;

    .primary {
      margin: 0;
      margin-left: 10px;
    }
  }
}

/deep/ .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
/deep/ .el-dialog .el-dialog__body{
  flex:1;
  overflow:auto;
}
.ml-b {
  margin-bottom: 20px;
}
/deep/.el-radio {
  margin-bottom: 5px;
}
/deep/.el-radio-group {
  width: 615px;
}
</style>