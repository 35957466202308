<template>
  <div>
    <order-top
        @shopcode="ShopCdoe"
        @statuskey="StatusKey"
        @time="time" @ship="ship"
        @defray="defray"
        @abnormalState="abnormalState"
        @importOrder="importOrder"
        @subAccountChange="subAccountChange"
    />
    <order-list :orderList="orderList" @keyword="keyOrder" @cancel="cancel" @copy="copyOrder" @del="del" @renew="renew"
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                @update="renew"
    />
    <gallery-foot :meta="meta" @currentPage="currentPage"/>

    <!--    导入店铺订单-->
    <import-store-orders
        :importStoreOrdersShow="importStoreOrdersShow"
        @close="importStoreOrdersShow=$event"
    />
  </div>
</template>

<script>

import OrderList from './components/orderList.vue'
import orderTop from './components/orderTop.vue'
import GalleryFoot from "@/components/publicTab/index";
import {listOrder, cancelOrder, copyOrder, deleteOrder} from '@/api/order'
import {
  getBeginWeek, //本周的开始时间
  getEndWeek,  // 本周的结束时间
  getBeginToday, // 今天的开始时间
  getCurrentDate, //当前时间
  getBeginYesterday, //昨天的开始时间
  getEndYesterday, // 昨天的结束时间
  getBeginLastWeek, //上周的开始时间
  getEndLastWeek, // 上周的结束时间
  getBeginMonth, // 本月的第一天
  getEndMonth, //本月的最后一天，
  getBeginLastMonth, //上个月的一天
  getEndLastMonth, //上个月最后一天
  year
} from '@/utils/timejs.js';

import ImportStoreOrders from "@/views/order/orderManagement/components/importStoreOrders";

export default {
  components: {ImportStoreOrders, orderTop, OrderList, GalleryFoot},

  data() {
    return {
      // 导入订单弹窗状态
      importOrderShow: false,
      // 订单列表
      orderList: [],
      // 店铺code
      code: null,
      // 关键字
      keyword: null,
      // 状态
      status: null,
      startTime: null, //今天开始时间
      endTime: null,//现在的时间
      // 发货状态
      shipStatus: undefined,
      // 支付状态
      payStatus: undefined,
      // 是否异常
      exceptionStatus: undefined,
      meta: {per_page: 1},
      page: 1,
      loading: true,
      importStoreOrdersShow: false, //导入店铺订单弹窗状态
      account: undefined,   //子账号
    }
  },
  created() {
    this.listOrder()
  },
  mounted() {

  },
  methods: {
    //导入订单
    importOrder() {
      this.importStoreOrdersShow = true
      // this.importOrderShow = true
    },
    //更新列表
    renew() {
      this.listOrder()
    },
    async listOrder() {
      try {
        this.loading = true
        const {data} = await listOrder({
          page: this.page,
          startTime: this.startTime || undefined,
          endTime: this.endTime || undefined,
          status: this.status || undefined,
          shipStatus: this.shipStatus || undefined,
          payStatus: this.payStatus || undefined,
          orderNumber: this.keyword || undefined
        })
        this.orderList = data.data
        this.meta = data.meta
        this.loading = false
      } catch (e) {
        this.$message.error(e)
      }
    },
    // 分页
    currentPage(val) {
      this.page = val

      this.listOrder()
    },
    ShopCdoe(code) {
      this.code = code
      this.page = 1
      this.listOrder()
    },
    // 关键字搜索
    keyOrder(val) {
      this.keyword = val
      this.page = 1
      this.listOrder()
    },
    //子账号
    subAccountChange(val) {
      this.page = 1
      this.account = val
      this.listOrder()
    },
    // 状态
    StatusKey(key) {
      this.status = key
      this.page = 1
      this.listOrder()
    },
    //发货状态
    ship(val) {
      if (val === 'null') {
        this.shipStatus = undefined
        this.page = 1
        this.listOrder()
      } else {
        this.shipStatus = val
        this.page = 1
        this.listOrder()
      }

    },
    //支付状态
    defray(val) {
      if (val === 'null') {
        this.payStatus = undefined
        this.page = 1
        this.listOrder()
      } else {
        this.payStatus = val
        this.page = 1
        this.listOrder()
      }
    },
    //异常状态
    abnormalState(val) {
      if (val === 'null') {
        this.exceptionStatus = undefined
        this.page = 1
        this.listOrder()
      } else {
        this.exceptionStatus = val
        this.page = 1
        this.listOrder()
      }

    },
    // 取消订单
    async cancel(number) {

      try {
        const {message} = await cancelOrder(
            {
              orderNumber: number
            }
        )

        this.$message({
          message,
          type: 'success'
        });
        await this.listOrder()
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 删除订单
    async del(number) {
      try {
        const {message} = await deleteOrder(
            {
              orderNumber: number
            }
        )
        this.$message({
          message,
          type: 'success'
        });
        await this.listOrder()
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 复制订单
    async copyOrder(number) {
      try {
        const {message} = await copyOrder(
            {
              orderNumber: number
            }
        )

        this.$message({
          message,
          type: 'success'
        });
        await this.listOrder()
      } catch (e) {

        this.$message.error(e);
      }
    },
    // 更改时间
    time(event) {

      if (event === '全部') {
        this.startTime = null
        this.endTime = null
      } else if (event === '今天') {
        this.startTime = Date.parse(getBeginToday()) / 1000
        this.endTime = Date.parse(getCurrentDate()) / 1000

      } else if (event === '昨天') {
        this.startTime = getBeginYesterday() / 1000
        this.endTime = parseInt(getEndYesterday() / 1000)

      } else if (event === '本周') {
        this.startTime = getBeginWeek() / 1000
        this.endTime = parseInt(getEndWeek() / 1000)
      } else if (event === '本月') {
        this.startTime = getBeginMonth() / 1000
        this.endTime = parseInt(getEndMonth() / 1000)
      } else if (event === '上月') {
        this.startTime = getBeginLastMonth() / 1000
        this.endTime = parseInt(getEndLastMonth() / 1000)
      } else if (event === '今年') {

      }
      this.page = 1
      this.listOrder()
    },
  }
}
</script>

<style scoped lang="less">

</style>
