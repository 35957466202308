<template>
  <div class="info">
<el-dialog
  title="收件人信息"
  :visible.sync="iShow"
  width="444px"
  :before-close="handleClose">
  <div class="infobox">
      <div class="text">收件人姓名</div>
      <div>{{AddressInfo.first_name}}</div>
  </div>
  <div class="infobox">
      <div class="text">联系电话</div>
      <div>{{AddressInfo.phone}}</div>
   </div>
  <div class="infobox" v-if="AddressInfo.country !== 'CN'">
      <div class="text">邮政编码</div>
      <div>{{AddressInfo.zip}}</div>
  </div>
  <div class="infobox">
      <div class="text">国家</div>
      <div>{{AddressInfo.country}}</div>
   </div>
  <div class="infobox">
      <div class="text">州/省/区</div>
      <div>{{AddressInfo.state}}</div>
   </div>
  <div class="infobox">
      <div class="text">市</div>
      <div>{{AddressInfo.city}}</div>
  </div>
  <div class="infobox">
      <div class="text">详细地址</div>
      <div>{{AddressInfo.address1}}</div>
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
  props:{
      iShow:{
          type:Boolean
      },
      AddressInfo:{
          type:Object
      }
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
   handleClose() {
    this.$emit('IShow',false)
   },
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
   background: #FFFFFF;
   box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
   border-radius: 10px;
}
/deep/.el-dialog__header{
    padding: 20px 0 17px;
    margin: 0 20px;
    border-bottom: 1px solid #d8d8d8;
    font-size: 18px;
}
/deep/.el-dialog__body{
    padding: 20px 40px;
}
.infobox{
    margin-bottom:13px;
    display: flex;
    font-size: 14px;
    color:#333333;

    .text{
        width: 70px;
        text-align: right;
        color: #999999;
        margin-right:20px;
    }
}
</style>
