<template>
  <el-dialog
    title="编辑收货人信息"
    :visible.sync="editConsigneeInfoStatus"
    width="30%"
    :before-close="handleClose"
  >
    <el-form label-width="100px">
      <el-form-item label="收件人姓名">
        <el-input v-model="AddressInfo.first_name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="AddressInfo.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮政编码">
        <el-input v-model="AddressInfo.zip"></el-input>
      </el-form-item>
      <el-form-item label="国家">
        <el-input disabled v-model="AddressInfo.country"></el-input>
      </el-form-item>
      <el-form-item label="州/省/区">
        <el-input v-model="AddressInfo.state"></el-input>
      </el-form-item>
      <el-form-item label="市">
        <el-input v-model="AddressInfo.city"></el-input>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input v-model="AddressInfo.address1"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="modifyInfo" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateAddress } from "@/api/order";
export default {
  name: "editConsigneeInfo",
  components: {},
  props: {
    editConsigneeInfoStatus: {
      type: Boolean,
    },
    AddressInfo: {
      type: Object,
    },
  },
  data() {
    return {
        loading:false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async modifyInfo() {
        this.loading = true
      try {
        const {message} = await updateAddress({
          fullName:this.AddressInfo.first_name,
          country:this.AddressInfo.country,
          phone:this.AddressInfo.phone,
          state:this.AddressInfo.state,
          city:this.AddressInfo.city,
          county:this.AddressInfo.county,
          address:this.AddressInfo.address1,
          postal:this.AddressInfo.zip,
          number:this.AddressInfo.number
        });
        this.$message.success(message)
        this.$emit('updateOrderList')
      } catch (e) {
        this.loading = false
        this.$message.error(e);
      }
      this.loading = false
    },
    handleClose() {
      this.$emit("cloneDialog", false);
    },
  },
};
</script>

<style scoped lang="scss"></style>
