import request from "@/utils/request";
const prefix = '/logistics'
//获取运费试算
export const getLogisticsWithPriceList = (
    {
        country,
        weight
    },
  callback
) => {
    const data = {
        country,
        weight
    }
    
    return request(`${prefix}/getLogisticsWithPriceList`, { params:data,method: 'get'}, callback)
}
// 物流详细
export const getLogisticsInfo = (
  callback
) => {

    
    return request(`${prefix}/getLogisticsInfo`, { params:{},method: 'get'}, callback)
}

// 物流详细
export const getLogisticsInfoReal = (
    { logisticCode },
    callback
) => {

   const params = {
       logisticCode
   }
    return request(`${prefix}/getLogisticsInfoReal`, { params,method: 'get'}, callback)
}