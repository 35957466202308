<template>
  <el-dialog
      :visible.sync="importOrderShow"
      :before-close="handleClose"
  >
    <div slot="title" class="title">导入订单 <span class="title_text">（导入店铺：{{storeName}}）</span></div>
    <el-row type="flex" justify="center" class="el_row">
      <el-col>上传订单<span class="quantity">{{ orderStatusList.totalamount }}</span></el-col>
<!--      <el-col>订单重复<span class="quantity">{{ repeatOrder || 0}}</span></el-col>-->
<!--      <el-col> 订单不符合要求 <span class="quantity">{{ unMatchStatusOrder || 0 }}</span></el-col>-->
<!--      <el-col>订单不属于本平台<span class="quantity">{{ unSystemSkuOrder || 0 }}</span></el-col>-->
    </el-row>

    <template v-if="importedSuccessfully">
<!--      <span class="active" @click="goAbnormalOrder">前往</span>-->
      <div class="reason">异常订单（点击确定按钮后前往异常订单处理）</div>
      <el-row class="reasonForOrder">
        <el-col :span="10" style="padding-left: 20px">订单号</el-col>
        <el-col :span="14">原因</el-col>
      </el-row>
      <div class="scorll">
        <el-row class="orderContent" v-for="(item,index) in orderNumber" :key="index">
          <el-col :span="10" style="padding-left: 20px">{{ item.val }}</el-col>
          <el-col :span="14">{{ item.message }}</el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirmUpload">确 定</el-button>
      </span>
    </template>
    <template v-else>
      <div class="importedSuccessfully_box">
        <div class="importedSuccessfully_icon">
          <i class="el-icon-circle-check"></i>
        </div>
        <div class="importedSuccessfully_txet">导入成功！</div>
        <div>
          <el-button size="mini" @click="okToComplete">确认完成</el-button>
        </div>
      </div>
    </template>


  </el-dialog>
</template>

<script>
import {confirmOrder} from "@/api/order";

export default {
  props: {
    importOrderShow: {
      type: Boolean,
      default: false
    },
    orderNumber: {
      type: Array,
      default: () => ([])
    },
    file: {

    },
    template: {
      type: String,
      default: null
    },
    store: {
      type: String,
      default: null
    },
    shopList:{
      type:Array,
      default:()=>[]
    },
    orderStatusList:{
      type:Object
    }
  },
  name: "importOrder",
  data: () => ({
    importedSuccessfully:true,// 导入订单成功
  }),
  mounted() {

  },
  methods: {
    //
    goAbnormalOrder(){
      this.$router.push({path:'abnormalOrder'})
    },

    // 退出
    handleClose() {
      this.$emit('close', false)

    },
    // 确定完成
    okToComplete() {
      this.$router.push({name: 'importRecords'})
    },
    //确定上传
    async confirmUpload() {
      console.log(this.template)
      try {
        const data = await confirmOrder({
          storeCode: this.store,
          type: this.template,
          file: this.file
        })

        if(data.msg = "success") {
          this.importedSuccessfully = false
        }
      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    }

  },
  computed:{
    repeatOrder() {
      return this.orderStatusList?.list?.repeatOrder?.count
    },
    unMatchStatusOrder() {
      return this.orderStatusList?.list?.unMatchStatusOrder?.count
    },
    unSystemSkuOrder() {
      return this.orderStatusList?.list?.unSystemSkuOrder?.count
    },
    storeName(){
      return this.orderStatusList?.storeInfo?.name
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  width: 800px;
  height: 533px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;

  .title_text {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.quantity {
  font-size: 14px;
  font-weight: 600;
  color: #FA4A14;
  line-height: 20px;
  margin-left: 10px;
}

.el_row {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  padding: 10px;
  border: 1px solid #D8D8D8;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

.reason {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-bottom: 5px;
}

.reasonForOrder {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  background: #F7F7F7;
}

.orderContent {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
  border-bottom: 1px solid #D8D8D8;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
}

.scorll {
  height: 265px;
  overflow: auto;
}

/* 滚动槽（轨道）宽高 */
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 20px; /*对水平流动条有效*/

}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: #D8D8D8;
}

.importedSuccessfully_box {
  height: 150px;
  width: 150px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-icon-circle-check {
    font-size: 26px;
    font-weight: 600;
    color: rgba(250, 74, 20, 0.5);
    margin-bottom: 9px;
  }

  .importedSuccessfully_txet {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

/deep/ .el-button {
  width: 90px;
  height: 40px;
  background: #FA4A14;
  border-radius: 5px;
  color: #fff;
  margin-left: 10px;
}
/deep/.el-col {
  border: 1px solid transparent;
}
.active {
  color: #FA4A14;
  cursor: pointer;
}
</style>