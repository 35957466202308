<template>
  <div>
<el-dialog
  :visible.sync="warningShow"
  width="420px"
  :before-close="handleClose">
  <div class="txt_box">
      <i class="el-icon-warning-outline"></i>
      <div class="tab_box" v-if="tabid === 1">复制订单会创建一个相同订单，确定复制吗？</div>
      <div class="tab_box" v-if="tabid === 2">您确认取消订单吗？</div>
      <div class="tab_box" v-if="tabid === 3">您确认删除订单吗？？</div>
  </div>
  <div slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="warnSure">确 定</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
    props:{
        warningShow:{
            type:Boolean
        },
        tabid:{
            type:Number
        }
    },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
      handleClose(){
          this.$emit('warnClose',false)
      },
      warnSure(){
          this.$emit('warnSure',false)
      }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
background: #FFFFFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
border-radius: 10px;
}
.txt_box{
    text-align: center;
    .el-icon-warning-outline{
        font-size: 28px;
        color: #FA4A14;
    }
    .tab_box{
        margin-top: 20px;
    }
}
.dialog-footer{
    text-align: center;
}
</style>
