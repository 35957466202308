<template>
  <div>
<more-logistics :MoreShow="MoreShow" @more="More"/>
<el-dialog
  title="编辑产品"
  :visible.sync="EditShow"
  width="1000px"
  :before-close="handleClose">
      <div class="consignee-title">收件人信息</div>
      <div class="consignee-body" v-if="Amend">
         <span>{{OrderInfo.last_name}}</span>
          <span>{{OrderInfo.phone}}</span>
          <span>{{OrderInfo.country}}</span>
          <span>{{OrderInfo.state}}</span>
          <span>{{OrderInfo.city}}</span>
          <span>{{OrderInfo.address1}}</span>
          <span>{{OrderInfo.zip}}</span>
          <span class="color ml20" @click="amend">修改</span>
          <span class="color" @click="addAddress">设为常用地址</span>
     </div>
    <div v-else>
    <el-form  :inline="true"  ref="ruleForm" :model="formInfo" :rules="rules" class="demo-form-inline amend_info" size="mini"  >
              <el-form-item label="收件人姓名" prop="name">
                <el-input v-model="formInfo.name" placeholder="请输入收件人姓名"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="phone">
                <el-input v-model="formInfo.phone" placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item label="邮政编码" prop="code">
                <el-input v-model="formInfo.code" placeholder="请输入邮政编码"></el-input>
              </el-form-item>
              <el-form-item label="国家" prop="country">
                 <el-select class="select_country" v-model="formInfo.country" placeholder="请选择国家" @change="changeCountry">
                   <el-option 
                   v-for="(item, index) in countrylist"
                   :key="index"
                   :label="item.cnname" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="州/省/区" prop="state">
                <el-input v-model="formInfo.state" placeholder="请输入州/省/区"></el-input>
              </el-form-item>
              <el-form-item label="市" prop="city">
                <el-input v-model="formInfo.city" placeholder="请输入市"></el-input>
              </el-form-item>
              <div>
              <el-form-item label="详细地址" prop="address" style="width: 930px;">
                <el-input class="address" v-model="formInfo.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
              </div>
              <el-form-item>
              <el-button size="mini" class="sureBtn"  @click="sure(OrderInfo.id)">确定</el-button>
              </el-form-item>
    </el-form>
          
          <div class="common">
              <div class="common_title">常用地址（最多可保存5条地址）</div>
              <div class="common_body" v-for="(item2,index2) in addressLsitiuser" :key="index2">
                  <span class="text">收货人:</span>
                  <span>{{item2.last_name}}</span>
                  <span class="text">联系电话:</span>
                  <span>{{item2.phone}}</span>
                  <span class="text">地址:</span>
                  <span>{{item2.country}} {{item2.state}} {{item2.city}} {{item2.address1}}</span>
                  <span class="color" @click="useAddress(item2)">使用</span>
                  <span class="color">编辑</span>
                  <span class="color" @click="delAddress(item2.id)">删除</span>
              </div>
          </div>
    </div>
<!--    <div class="consignee-title">物流信息</div>-->
<!--      <div class="consignee-body">-->
<!--          <span>顺丰国际小包挂号</span>-->
<!--          <span>运费：¥12.36</span>-->
<!--          <span>物流时间：10-15工作日</span>-->
<!--          <span class="color ml20" @click="moreShow">更多物流</span>-->
<!--     </div>-->
  
     <el-row class="consignee-title">
         <el-col :span="9">产品信息</el-col>
         <el-col :span="7">发货仓</el-col>
         <el-col :span="5">金额</el-col>
         <el-col :span="3">操作</el-col>
     </el-row>
    <div class="main">
      <el-row class="list" v-for="(item, index) in OrderInfo.order_item" :key="index">
         <el-col :span="9" class="list-info">
             <div class="list-left">
                 <img :src="item.imgThumb.length ? item.imgThumb[0].img : url" alt="">
             </div>
             <div class="list-rigth">
                 <div>{{item.name}}</div>
                 <div><span class="text">成品ID: </span>{{item.product_code}}</div>
<!--                 <div><span class="text">材质: </span>纯棉</div>-->
                 <div v-if="amendShow && am === index">
                  <div> 
                    <span class="text">颜色:</span>
                   <el-select size="mini" v-model="colorValue" @change="colorS" placeholder="请选择">
                   <el-option
                     v-for="(item7,index7) in colorArr"
                     :key="index7"
                     :label="item7.color_name"
                     :value="item7.color_id"
                     >
                   </el-option>
                  </el-select>
                  </div>
<!--                  <div >-->
<!--                    <span class="text">规格:</span>-->
<!--                    <el-input size="mini" v-model="size" placeholder="请选择尺寸"></el-input>-->
<!--                  </div>-->
                  <div>
                   <span class="text">规格:</span>
                   <el-select size="mini" v-model="sizeValue" placeholder="请选择" @change="sizeValueChange($event,item)">
                   <el-option
                     v-for="v in sizeArr"
                     :key="v.id"
                     :label="v.size_name"
                     :value="v.size_id"
                   >
                   </el-option>
                  </el-select>
                  </div>
                </div>
                 <div v-else><span class="text">规格: </span>{{item.options !== null ? item.options.color :'暂无'}}/{{item.options !== null ? item.options.size : '暂无'}}</div>
                </div>
             
         </el-col>



         <el-col :span="7">平台仓</el-col>
         <el-col :span="5" class="money">
             <div>
               <span class="text">单价：</span><span>￥{{ item.product_price}}</span>
<!--               <span class="delete_price" v-if="item.product_price !== item.oldValue && !!item.oldValue">￥{{item.oldValue}}</span>-->
             </div>
             <div v-if="amendShow && am === index">
               <span class="text">数量：</span>
               <el-input-number size="mini" @change="numChange($event,item)" v-model="item.total_qty" controls-position="right" :min="1"></el-input-number>
             </div>
             <div v-else><span class="text">数量：</span>{{item.total_qty}}</div>
             <div><span class="text">小计：</span>￥{{ setSum(item.total_qty , item.product_price)}}</div>
<!--           item.total_qty * item.product_price-->
         </el-col>
          <el-col :span="3" v-if="amendShow && am === index">
             <span class="color" @click="modificationSure(item.id,item.total_qty,item.code,OrderInfo.id,item)">确定</span>
             <span class="color" @click="modificationCancel">取消</span> 
             <span class="color" @click="del(item.code,OrderInfo.id)">删除</span> 
        </el-col>
         <el-col :span="3" v-else><span class="color" @click="modification(index,item.id,item)">修改</span> </el-col>
        
     </el-row>
     </div>
     <div class="foot">
         <div class="foot-left">
             <div class="dis">
                 <div class="name">订单归属店铺:</div>
                 <div class="shop">{{ OrderInfo.platform_store ? OrderInfo.platform_store.name : '' }}</div>
             </div>
             <div class="dis">
                 <div class="name">备注:</div>
                 <el-input
                   type="textarea"
                   :rows="2"
                   placeholder="请输入内容"
                   v-model="textarea">
                </el-input>
             </div>
         </div>
         <div class="foot-rigth">
             <div class="fr">
                 <div class="wind_up">{{OrderInfo.total_qty}}件商品，商品金额总计：</div>
                 <div class="foot_money">¥{{OrderInfo.discount_price}}</div>
             </div>
             <div class="fr">
                 <div class="wind_up">物流费：</div>
                 <div class="foot_money">¥{{OrderInfo.ship_price}}</div>
             </div>
             <div class="fr">
                 <div class="wind_up">应付总额：</div>
                 <div class="foot_money">¥{{OrderInfo.total_price}}</div>
             </div>
         </div>
     </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button @click="handleClose">保存</el-button>
    <el-button type="primary" @click="pay">支付</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import MoreLogistics from './MoreLogistics.vue'
import { getOrderItemSkuList } from '@/api/order'
import { countryList, userAddressList, addUserAddress, deleteUserAddress ,finalProductPrice} from '@/api/site'
import moren from '@/assets/moren.png'
import BigNumber from "bignumber.js";
export default {
  components: { MoreLogistics },
  props:{
      EditShow:{
          type:Boolean
      },
      OrderInfo:{
          type:Object
      }
  },
  data () {
    return {
      pCode:'',
      sizeValue:'',
          url:moren,
         textarea: '',  //备注
         MoreShow:false, //展示更多物流
         amendShow:false,  //修改操作
          infoList :[],//

         am: 0, //控制变量
        //  表单数据
        formInfo:{
            name:'',  //姓名
            phone:'',  //联系电话
            code:'',  // 邮政编码
            country:'',  //国家
            state:'',   //省
            city:'',  //市
            address:''  //详细地址
        },
        // 颜色下拉
        colorSele:[],
        colorCode:'',
        countrylist:[],   //国家列表
        addressLsitiuser:[],  //用户常用列表
        colorArr:[],//颜色list
        sizeArr:[],//规格
        priceValue:null,//单价
        oldValue:null,
      num:null,
        // 尺寸
        size:'',
        // 颜色value
        colorValue:'',
        orderItem:[],
        // 表单验证
        rules:{
            name:[
                { required: true, message: '请输入收件人名称', trigger: 'blur' },
            ],
            phone:[
                { required: true, message:'请输入手机号', trigger:'blur' },
                // { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' },
            ],
            code:[
                { required: true, message:'请输入邮政编码', trigger:'blur' }
            ],
            country:[
                { required: true, message:'请输入国家', trigger:'blur' }
            ],
            state:[
                { required: true, message:'请输入州/省/区', trigger:'blur' }
            ],
            city:[
                { required: true, message:'请输入城市', trigger:'blur' }
            ],
            address:[
                { required: true, message:'请输入详细地址', trigger:'blur' }
            ],
        },
        Amend:true  //默认没有修改
    }
  },
  created () {
      // this.countryList()
      this.userAddressList()
  },
  mounted () {
  },
  methods: {
    //数量的变化
    async numChange(e,item){

      let productId
      this.colorSele.forEach(item=>{
        if(item.size_id === this.sizeValue) {
          productId  = item.product_id
        }
      })
        try {
          const {data} = await finalProductPrice({
            productId:productId,
            sizeId:this.sizeValue,
            quantity:item.total_qty
          })
          item.product_price = data?.info.finalPrice
          item.oldValue = data?.info.originalPrice
        }catch (e) {
          this.$message.error(e?.msg)
        }
    },




    //规格变化
    sizeValueChange(e,item){
      this.colorSele.forEach(item=>{
        if(item.size_id === this.sizeValue) {
          this.pCode = item.code
        }
      })


        this.numChange(e,item)
    },







   handleClose() {
    this.$emit('edit',false)
     this.amendShow = false
     this.am = 0
     this.infoList = []
     this.colorArr = []
     this.sizeArr = []
     this.priceValue = null
    },

   moreShow(){
    this.MoreShow = true
    },
    More(val){
        this.MoreShow = val
    },
    // 修改信息
    amend(){
        this.Amend = false
        this.formInfo.name = this.OrderInfo.last_name
        this.formInfo.phone = this.OrderInfo.phone
        this.formInfo.country = this.OrderInfo.country
        this.formInfo.state = this.OrderInfo.state
        this.formInfo.city = this.OrderInfo.city
        this.formInfo.address = this.OrderInfo.address1
        this.formInfo.code = this.OrderInfo.zip
    },
    // 修改订单商品信息
  async modification(i,id,item){
    console.log(this.OrderInfo)
    this.sizeValue = null
    this.colorValue = null
    this.infoList = []
    this.colorArr = []
    this.sizeArr = []
    this.priceValue = null
    const { color_id, size_id ,total_qty} = item
        this.num = total_qty
        this.amendShow = true
        this.am = i
        try{
        const { data, msg } = await getOrderItemSkuList({orderItemId:id})
        this.colorSele = data
          console.log(data)


      //获取颜色
          let colorObj = {}
          data.forEach(item=>{
            if(item.size_id === size_id) {
              this.pCode = item.code
            }


            if(!this.colorArr.length) {
              this.colorArr.push({
                color_name:item.color_name,
                color_id:item.color_id,
                id:item.id
              })

            } else {
              this.colorArr.forEach(val=>{
                if(val.color_id !== item.color_id) {
                  colorObj = {
                    color_name : item.color_name,
                    color_id : item.color_id,
                    id : item.id
                  }
                this.colorArr.push(colorObj)
                }
              })
            }

          })


        //获取规格
          let sizeObj = {}
          data.forEach(item=>{
            if(!this.sizeArr.length) {
              this.sizeArr.push({
                size_name:item.size_name,
                size_id:item.size_id,
                id:item.id
              })
            } else {
              this.sizeArr.forEach(val=>{
                if(val.size_name !== item.size_name) {
                  sizeObj = {
                    size_name : item.size_name,
                    size_id : item.size_id,
                    id:item.id
                  }

                }
              })
              this.sizeArr.push(sizeObj)
            }
          })
          this.colorValue = color_id
          this.sizeValue = size_id

        } catch(e){
            console.log(e);
        }
    },
    // 选中颜色选规格
    colorS(e){
      console.log(e)
       // for(let i=0;i<this.colorSele.length;i++){
       //     if(id===this.colorSele[i].id){
       //
       //         this.size = this.colorSele[i].size_name
       //         this.colorCode = this.colorSele[i].code
       //     }
       // }
    },
       // 确认修改订单商品信息
    modificationSure(id,num,code,oid,item){

      this.$emit('Modification',id,this.pCode,num,oid)
      this.amendShow = false
      this.infoList = []
      this.colorArr = []
      this.sizeArr = []
      // this.priceValue = null
    },
    // 删除
    del(code,id){

        this.$emit('DeletOItem',code,id)
    },
    // 取消修改
    modificationCancel(){
      this.amendShow = false
      this.infoList = []
      this.colorArr = []
      this.sizeArr = []
      this.priceValue = null
      this.$emit('update')
    },
    //   国家列表
 async countryList(){
          try{
              const { data, msg } = await countryList()
              this.countrylist = data
          } catch(e){
              this.$message.error(e)
          }
    },
    // 获取常用地址
async userAddressList(){
    try{
        const { data, msg } = await userAddressList()

        this.addressLsitiuser = data
    } catch(e){
        console.log(e);
    }
},
// 使用常用地址
useAddress(val){

        this.formInfo.name = val.last_name
        this.formInfo.phone = val.phone
        this.formInfo.code = val.zip
        this.formInfo.country = val.country
        this.formInfo.state = val.state
        this.formInfo.city = val.city
        this.formInfo.address = val.address1
},
// 设为常用地址
async addAddress(){

    try{
        const { data, msg } = await addUserAddress({
            last_name: this.OrderInfo.last_name,
            address1:this.OrderInfo.address1,
            state:this.OrderInfo.state,
            city:this.OrderInfo.city,
            country:this.OrderInfo.country,
            zip:this.OrderInfo.zip,
            phone:this.OrderInfo.phone
        })

        this.$message({
          message: msg,
          type: 'success'
        });
        this.userAddressList()
    } catch(e){
        console.log(e);
    }
},
// 删除常用地址
async delAddress(id){

    try{
        const { data, msg } = await deleteUserAddress({addressId:id})
           this.$message({
          message: msg,
          type: 'success'
        });
        this.userAddressList()
    } catch(e){
        console.log(e);
    }
},
    // 改变国家
changeCountry(){
        this.formInfo.state = ''
        this.formInfo.city = '' 
},
    // 确认修改地址信息
    sure(id){
        this.$refs.ruleForm.validate((valid) => {
            if(valid){

                this.$emit('receiveOrder',id,this.formInfo)
                this.Amend = true
            } else {

            }
        })

    },
    pay(){
        this.$emit('editPay',this.OrderInfo.number,this.OrderInfo.total_price)
        this.$emit('edit',false)
    }
    
  },
  watch:{

  },
  computed:{
    setSum(){
      return (item,val)=>{
        return new BigNumber(item).times(new BigNumber(val)).toFixed(2)
      }
    }
  }
}
</script>

<style scoped lang="less">


.color{
    color: #fa4a14;
    cursor: pointer;
    margin-right: 10px !important;
}
.text{
    color: #666666;
    margin-right: 6px !important;
}
.ml20{
    margin-left: 20px;
}


.el-input{
    width: 130px;
}
/deep/.el-form-item--mini{
    .el-form-item__label{
        color: #333;
        width: 100px;
    }
}
.el-input-number{
 /deep/.is-controls-right{
   .el-input__inner{
     padding: 0;
   }
 }
}
/deep/.el-dialog{
background: #FFFFFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
border-radius: 10px;
}
/deep/.el-dialog__header{
    padding: 20px 0 17px;
    margin: 0 20px;
    border-bottom: 1px solid #d8d8d8;
    font-size: 18px;
}
/deep/.el-dialog__body{
    padding: 10px 20px 30px;
    // height: 500px;
    overflow-x: hidden;
}
/deep/.el-input--mini{
    .el-input__inner{
        width: 100%;
    }
}
.select_country{
    width: 130px;
}
.main{
    height: 400px;
    overflow: auto;
}
.consignee-title{
    padding: 10px;
    background: #F7F7F7;
    color: #333333;
    font-size: 15px;
}
.consignee-body{
    color: #333333;
    font-size: 14px;
    padding: 10px 15px;
    border: 1px solid #F7F7F7;
    margin-bottom: 10px;
    span{
        margin-right:10px;
    }
}
.amend_info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    padding-right: 30px;
    margin-top: 10px;
    color: #333;
    .address{
        width: 810px;
    }
}
.sureBtn{
   width: 60px;
   height: 30px;
   background: #FA4A14;
   border-radius: 2px;
   color: #fff;
   margin-left: 90px;
}
.common{
    padding-left: 20px;
    color: #333;
    margin: 25px 0;
    div{
        margin-bottom: 10px;
    }
    span{
        margin-right: 20px;
    }
}
.list{
    padding: 20px 10px;
    border: 1px solid #F7F7F7;
    color: #333;
    .list-info{
    display: flex;
    .list-left{
        width: 90px;
        height: 90px;
        margin-right: 10px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .list-rigth{
        color: #333333;
        div{
            margin-bottom: 6px;
        }
    }
  }
  .money{
      div{
          margin-bottom: 6px;
      }
    .delete_price {
      text-decoration:line-through;
      margin-left: 10px;
      font-size: 12px;
      color: #cccccc;
    }
  }
}
.foot{
    padding: 10px;
    background: #F7F7F7;
    color: #333333;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  .foot-left{
      .el-textarea{
          width: 200px;
      }
      .dis{
          display: flex;
          margin-bottom: 6px;
          .name{
              width: 88px;
              text-align: right;
              margin-right: 8px;
          }
      }
  }
  .foot-rigth{
      .fr{
          display: flex;
          margin-bottom: 6px;
          .wind_up{
              width: 163px;
              text-align: right;
          }
          .foot_money{
              width: 100px;
              text-align: right;
          }
      }
  }
}
/* 滚动槽（轨道）宽高 */
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 8px; /*对水平流动条有效*/
  // background-color:red
}
/* 滚动槽（轨道）样式 */
::-webkit-scrollbar-track{
  border-radius: 8px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: #E8E8E8;
}

</style>
