<template>
  <div>
      <el-dialog
        title="查看详情"
        :visible.sync="detailsShow"
        width="1000px"
        :before-close="handleClose"
      >
        <div
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#fff"
        >
          <div class="consignee-title">收件人信息</div>
          <div class="consignee-body">

            <span>{{OrderInfo.last_name}}</span>
            <span>{{OrderInfo.phone}}</span>
            <span>{{OrderInfo.country}}</span>
            <span>{{OrderInfo.state}}</span>
            <span>{{OrderInfo.city}}</span>
            <span>{{OrderInfo.address1}}</span>
            <span>{{OrderInfo.zip}}</span>
          </div>

          <div v-if="!!OrderInfo.ship_company">
            <div class="consignee-title">货运信息</div>
            <div  class="consignee-body">
              <div class="flex logisticsInfo ">
                <div class="logisticsInfo_left">
                  <span class="title">承运商：</span>
                  <span >{{OrderInfo.ship_company}}</span>
                </div>
                <div class="logisticsInfo_right">
                  <span class="title">订单号：</span>
                  <span>{{OrderInfo.number}}</span>
                </div>
              </div>
              <div class="flex logisticsInfo">
                <div  class="logisticsInfo_left">
                  <span class="title">代码：</span>
                  <span>{{ OrderInfo.ship_code }}</span>
                  </div>
                <div class="logisticsInfo_right">
                  <span class="title">货运号：</span>
                  <span>{{OrderInfo.ship_number}}</span>
                </div>
              </div>
<!--              <div class="flex logisticsInfo">-->
<!--                <div   class="logisticsInfo_left">-->
<!--                  <span class="title">创建时间：</span>-->
<!--                  <span>{{logisticsInfo.created_at}}</span>-->
<!--                </div>-->
<!--                <div class="logisticsInfo_right">-->
<!--                  <span class="title">物流跟踪号：</span>-->
<!--                  <span>{{logisticsInfo.tracking_numbers}}</span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
<!--          <div class="consignee-title">物流信息</div>-->
<!--          <div class="consignee_info">-->
<!--            <el-steps direction="vertical" :active="1" space="180%" v-if="!!logisticsList.length">-->
<!--              <el-step icon="el-icon-location" v-for="(item, index) in logisticsList" :key="index">-->
<!--                <div slot="title" class="time">{{item.a}}</div>-->
<!--                <div slot="description" class="miaosu">{{item.z}}</div>-->
<!--              </el-step>-->
<!--              &lt;!&ndash; <el-step icon="el-icon-location" title="步骤 2"></el-step>-->
<!--              <el-step title="步骤 3" description="这是一段很长很长很长的描述性文字"></el-step> &ndash;&gt;-->
<!--            </el-steps>-->
<!--            <div v-else class="LogisticsInfo">-->
<!--              暂无物流信息-->
<!--            </div>-->
<!--          </div>-->
          <el-row class="consignee-title">
            <el-col :span="11">产品信息</el-col>
            <el-col :span="9">发货仓</el-col>
            <el-col :span="4">金额</el-col>

          </el-row>
          <div class="product_info">
            <el-row class="list" v-for="(item, index) in OrderInfo.order_items" :key="index">
              <el-col :span="11" class="list-info">
                <div class="list-left">
                  <el-image :src="item.img ? item.img : url" alt="" fit="scale-down"></el-image>
                </div>
                <div class="list-rigth">
                  <div>{{item.name ? item.name : '暂无'}}</div>
                  <div><span class="text">成品ID: </span>{{item.id}}</div>
<!--                  <div><span class="text">材质: </span>纯棉</div>-->
                  <div><span class="text">规格: </span>{{item.options.color}}/{{item.options.size}}</div>
                </div>
              </el-col>
              <el-col :span="9">平台仓</el-col>
              <el-col :span="4" class="money">
                <div><span class="text">单价：</span>￥{{item.product_price}}</div>
                <div><span class="text">数量：</span>{{item.total_qty}}</div>
                <div><span class="text">小计：</span>￥{{item.total_price}}</div>
              </el-col>
            </el-row>
          </div>
          <div class="foot">
            <div class="foot-left">
<!--              <div class="dis">-->
<!--                <div class="name">订单归属店铺:</div>-->
<!--                <div class="shop">{{OrderInfo.platform_store ? OrderInfo.platform_store.name : '暂无'}}</div>-->
<!--              </div>-->
              <div class="dis">
                <div class="name">备注:</div>
                {{ OrderInfo.memo || '暂无' }}
              </div>
            </div>
            <div class="foot-rigth">
              <div class="fr">
                <div class="wind_up">{{OrderInfo.total_qty}}件商品，商品金额总计：</div>
                <div class="foot_money">¥{{OrderInfo.discount_price}}</div>
              </div>
              <div class="fr">
                <div class="wind_up">物流费：</div>
                <div class="foot_money">¥{{OrderInfo.ship_price}}</div>
              </div>
              <div class="fr">
                <div class="wind_up">应付总额：</div>
                <div class="foot_money">¥{{OrderInfo.total_price}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { getLogisticsInfo } from '@/api/logistics'
import moren from '@/assets/moren.png'
import {getLogisticsInfoReal} from "@/api/logistics";
export default {
  props:{
      detailsShow:{
          type:Boolean
      },
      OrderInfo:{
          type:Object
      },
  },
  data () {
    return {
      logisticsList:[],  //物流信息
      url:moren,
      loading:false
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClose() {
      this.logisticsList = []
        this.$emit('details',false)
    },
    // 物流详情国外
    // async getLogisticsInfoReal(){
    //   try {
    //     const {data}  = await getLogisticsInfoReal({
    //       logisticCode:this.OrderInfo.ship_number
    //     })
    //     this.logisticsList = data?.list?.track || data

    //   }catch (e) {
    //     this.$message.error(e?.msg)
    //   }
    // }
  },
  watch:{
    OrderInfo:{
      handler(){
        if(this.OrderInfo && this.OrderInfo.ship_number) {
          this.logisticsList = []
          // this.getLogisticsInfoReal()
          setTimeout(()=>{
            this.loading = false
          },1000)
        }

      }
    },
    detailsShow:{
      handler(){
        this.loading = true
        setTimeout(()=>{
          this.loading = false
        },2000)
      }
    }
  }
}
</script>
<style scoped lang="less">
.flex {
  display: flex;
}
.color{
    color: #fa4a14;
    cursor: pointer;
    margin-right: 10px !important;
}
.text{
    color: #666666;
    margin-right: 6px !important;
}
.ml20{
    margin-left: 20px;
}
/deep/.el-form-item--mini{
 .el-form-item__content{
     width: 200px;
 }
}
/deep/.el-form-item--mini{
    .el-form-item__label{
        color: #333;
        width: 88px;
    }
}
 
/deep/.el-dialog{
background: #FFFFFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
border-radius: 10px;
  margin-top: 5vh !important;

}
/deep/.el-dialog__header{
    padding: 20px 0 17px;
    margin: 0 20px;
    border-bottom: 1px solid #d8d8d8;
    font-size: 18px;
}
/deep/.el-dialog__body{
    padding: 10px 20px 30px;
}
.consignee-title{
    padding: 10px;
    background: #F7F7F7;
    color: #333333;
    font-size: 15px;
}
.consignee-body{
    color: #333333;
    font-size: 14px;
    padding: 10px 15px;
    border: 1px solid #F7F7F7;
    margin-bottom: 10px;
    span{
        margin-right:10px;
    }
  .logisticsInfo {
    margin-bottom: 10px;
    .logisticsInfo_left {
      margin-left: 30px;
      width: 400px;
      display: flex;
    }
    .logisticsInfo_right {
     flex: 1;
      display: flex;
    }
    .title {
      width: 100px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.consignee_info{
    color: #333333;
    font-size: 14px;
    padding: 10px 15px 10px 150px;
    border: 1px solid #F7F7F7;
    margin-bottom: 10px;
    height: 213px;
    //overflow-x: hidden;
    overflow: auto;
  .LogisticsInfo {
    margin-left: 250px;
  }
    span{
        margin-right:10px;
    }
    .time{
        position: absolute;
        left: -144px;
        top: 0;
        font-size: 14px;
        font-weight: 500;
        width: 150px;
    }
}
.amend_info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    padding-right: 30px;
    color: #333;
    .address{
        width: 830px;
    }
}
.product_info{
    height: 264px;
    max-height: 386px;
    overflow-x: hidden;
}
.sureBtn{
   width: 60px;
   height: 30px;
   background: #FA4A14;
   border-radius: 2px;
   color: #fff;
   margin-left: 90px;
}
.common{
    padding-left: 20px;
    color: #333;
    margin: 25px 0;
    div{
        margin-bottom: 10px;
    }
    span{
        margin-right: 20px;
    }
}
.list{
    padding: 20px 10px;
    border: 1px solid #F7F7F7;
    color: #333;
    .list-info{
    display: flex;
    .list-left{
        width: 90px;
        height: 90px;
        margin-right: 10px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .list-rigth{
        color: #333333;
        div{
            margin-bottom: 6px;
        }
    }
  }
  .money{
      div{
          margin-bottom: 6px;
      }
  }
}
.foot{
    padding: 10px;
    background: #F7F7F7;
    color: #333333;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  .foot-left{
      .el-textarea{
          width: 200px;
      }
      .dis{
          display: flex;
          margin-bottom: 6px;
          .name{
              width: 88px;
              text-align: right;
              margin-right: 8px;
          }
      }
  }
  .foot-rigth{
      .fr{
          display: flex;
          margin-bottom: 6px;
          .wind_up{
              width: 163px;
              text-align: right;
          }
          .foot_money{
              width: 100px;
              text-align: right;
          }
      }
  }
}
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: #E8E8E8;
}
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
}
</style>
