<template>
  <div class="order-top">
    <!-- 运费试算 -->
    <freight-trial :trialShow="trialShow" @trialS="trialS"/>
    <el-col class="left" :span="20">
      <!--        <ul class="shop">-->
      <!--          <li class="text">店铺</li>-->
      <!--          <li v-for="(item, index) in shopList" :key="index" :class="{ 'active':ShopActive === index }" @click="shopActive(index,item.code)">{{item.name}}</li>-->
      <!--        </ul>-->
      <ul class="time">
        <li class="text">日期</li>
        <li v-for="(item1, index1) in timeList" :key="index1" :class="{ 'active':timeActive === index1 }"
            @click="TimeActive(index1,item1)">{{ item1 }}
        </li>
      </ul>
      <ul class="status">
        <li class="text">订单状态</li>
        <li v-for="(item2, index2) in statusList" :class="{ 'active':statusActive === index2 }"
            @click="getStatusActive(index2,item2.code)" :key="index2">{{ item2.word }}
        </li>
      </ul>
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
        <el-form-item label="发货状态">
          <el-select v-model="formInline.shipped" clearable placeholder="所有" @change="deliveryStatus">
            <el-option v-for="(item,index) in shipStatus" :key="index" :label="item.word" :value=item.code></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="formInline.pay" clearable placeholder="所有" @change="paymentStatus">
            <el-option v-for="(item,index) in payStatus" :key="index" :label="item.word" :value=item.code ></el-option>
          </el-select>
        </el-form-item>
        <!--             <el-form-item label="工厂状态">-->
        <!--               <el-select v-model="formInline.unusual" clearable  placeholder="所有" @change="abnormal">-->
        <!--                 <el-option label="所有" value=null></el-option>-->
        <!--                 <el-option label="无异常" value=0></el-option>-->
        <!--                 <el-option label="有异常" value=1></el-option>-->
        <!--               </el-select>-->
        <!--             </el-form-item>-->
        <!--             <el-form-item label="账号">-->
        <!--               <el-select v-model="formInline.accountNumber" clearable  @change="subAccountChange" class="batch" style="width: 150px" size="mini">-->
        <!--                 <el-option-->
        <!--                     v-for="item in allSubAccount"-->
        <!--                     :key="item.id"-->
        <!--                     :label="item.phone"-->
        <!--                     :value="item.code">-->
        <!--                 </el-option>-->
        <!--               </el-select>-->
        <!--             </el-form-item>-->
      </el-form>
    </el-col>
    <el-col class="rigth" :span="4">
      <!--        <div class="sum">-->
      <!--          共<span>10</span>个-->
      <!--        </div> -->
<!--      <el-button class="leadInBtn" icon="el-icon-circle-plus-outline" size="mini" @click="importOrder">导入订单</el-button>-->
      <!--        <el-button class="count" size="mini" @click="trial">运费计算</el-button>-->
      <!--        <el-button class="count" size="mini" @click="trial">运费计算</el-button>-->
      <!--        <div class="overtime">有<span class="color">0</span>个超三天未发货的订单</div>-->
      <!--        <div class="overtime">有<span class="color">0</span>个超五天未发货的订单</div>-->
    </el-col>
  </div>

</template>

<script>
import {shopsScreen, orderStatus} from '@/api/order'
// import {getASubAccount} from "@/api/login";
import FreightTrial from './FreightTrial.vue'
import {orderSpecialStatus} from "@/api/order";

export default {
  components: {FreightTrial},
  data() {
    return {
      shopList: [{'code': null, 'name': '所有'}],  //店铺列表
      timeList: ['全部', '今天', '昨天', '本周', '本月', '上月'],
      statusList: [],   //状态列表
      OrderStatus: [],
      ShopActive: 0,  //店铺选中状态
      timeActive: 0,   //时间选中状态
      statusActive: 0, //状态选中状态
      formInline: {
        shipped: null,  //发货状态
        pay: null,      //支付状态
        unusual: null,   //异常
        accountNumber: null,  //子账号查询
      },
      trialShow: false,    //运费试算显示
      allSubAccount: [],    //账号列表
      payStatus: [], //发货状态
      shipStatus:[], //支付状态
    }
  },
  created() {
    // this.shopsScreen()
    this.orderStatus()
    // this.getASubAccount()
    this.orderSpecialStatus()
  },
  mounted() {

  },
  methods: {
    // 获取店铺列表
    async shopsScreen() {
      try {
        const {data} = await shopsScreen()
        this.shopList.push(...data.list)
      } catch (e) {
        this.$message.error(e)
      }

    },
    //获取子账号
    async getASubAccount() {
      try {
        const data = await getASubAccount()
        this.allSubAccount = data?.data
      } catch (e) {

        this.$message.error(e)
      }
    },

    // 获取状态列表
    async orderStatus() {
      try {
        const {data} = await orderStatus()
        // for(let i=0; i<data.keys.length;i++){
        //   let obj = {
        //     'key':null,
        //     'value':null
        //   }
        //   obj.key = data.keys[i]
        //   obj.value = data.values[i]
        //   this.statusList.push(obj)
        // }
        this.statusList = data
      } catch (e) {
        this.$message.error(e)
      }

    },
    // 店铺
    shopActive(i, code) {
      this.ShopActive = i
      this.$emit('shopcode', code)
    },
    // 时间
    TimeActive(i, time) {
      this.timeActive = i
      this.$emit('time', time)
    },
    // 状态
    getStatusActive(i, key) {
      this.statusActive = i
      this.$emit('statuskey', key)
    },
    //发货状态
    deliveryStatus() {
      this.$emit('ship', this.formInline.shipped)
    },

    async orderSpecialStatus() {
      try {
        const {data} = await orderSpecialStatus()
        this.payStatus = data?.payStatus
        this.shipStatus = data?.shipStatus
      } catch (e) {
        this.$message.error(e)
      }
    },
    //支付状态
    paymentStatus() {
      this.$emit('defray', this.formInline.pay)
    },
    //子账号改变
    subAccountChange() {
      this.$emit('subAccountChange', this.formInline.accountNumber)
    },
    //异常状态
    abnormal() {
      this.$emit('abnormalState', this.formInline.unusual)
    },
    // 运费试算展示
    trial() {
      this.trialShow = true
    },
    // 运费试算
    trialS(val) {
      this.trialShow = val
    },
    // 导入订单
    importOrder() {
      this.$emit('importOrder', true)
    }
  }
}
</script>

<style scoped lang="less">
.time,
.status{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 20px;

 &>li {
    margin-right: 20px;
    cursor: pointer;
  }

}

.el-select {
  width: 100px;
  margin-right: 20px;
}

.active {
  color: #FA4A14;
}

.text {
  color: #666666;
}

.color {
  color: #FA4A14;
}

.order-top {
  display: flex;
  border-bottom: 1px solid #D8D8D8;

  .rigth {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .leadInBtn {
      font-size: 18px;
      width: 150px;
      height: 40px;
      background: #FA4A14;
      box-shadow: 0px 0px 10px 0px #F93A00;
      border-radius: 5px;
      border: 0;
      color: #fff;
    }

    .count {
      width: 150px;
      height: 24px;
      font-size: 12px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #FA4A14;
      color: #FA4A14;
      padding: 0;
      margin-left: 0;
    }

    .overtime {
      width: 150px;
      height: 24px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      color: #666666;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
    }
  }
}

/deep/ .el-popper {
  min-width: 100px !important;
}

.el-input--mini {
  .el-input__inner {
    width: 100px !important;
    height: 26px !important;
    line-height: 26px !important;
  }
}
/deep/.el-select-dropdown__item {
  margin-right: 0 !important;
}
</style>
