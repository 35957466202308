<template>
  <div class="order-list">
    <!-- 编辑收货人信息 -->
    <EditConsigneeInfo :editConsigneeInfoStatus="editConsigneeInfoStatus" @cloneDialog="editConsigneeInfoStatus=$event" :AddressInfo="AddressInfo" @updateOrderList="updateOrderList"/>
    <!-- 收件人信息 -->
    <recipent-info :iShow="InfoShow" @IShow="info" :AddressInfo="AddressInfo" />
    <!-- 编辑物流 -->
    <edit-logistics :logisticsShow="logisticsShow" @logistics="Logistics" />
    <!-- 订单详情 -->
    <order-details
      :detailsShow="detailsShow"
      @details="Details"
      :OrderInfo="OrderInfo"
    />
    <!-- 警告弹窗 -->
    <warning-pop
      :warningShow="warningShow"
      :tabid="tabid"
      @warnClose="warnClose"
      @warnSure="warnSure"
    />
    <!-- 支付弹窗 -->
    <order-pay
      :PayPopShow="PayPopShow"
      @payShow="payShow"
      :amount="amount"
      :balance="balance"
      :orderNumbers="orderNumbers"
      @update="update"
    />
    <div class="order-column">
      <div class="column-left"></div>
      <div class="column-rigth">
        <el-input
          class="search"
          v-model="searchValue"
          placeholder="请输入订单号"
          clearable
          size="mini"
        >
          <el-button
            class="searchbtn"
            slot="append"
            icon="el-icon-search"
            @click="Search"
          ></el-button>
        </el-input>
      </div>
    </div>
    <el-row class="order-body">
      <el-col :span="8">订单详细</el-col>
      <el-col class="pd20" :span="4">金额</el-col>
      <el-col class="pd20" :span="3">当前状态</el-col>
      <el-col class="pd20" :span="4">时间</el-col>
      <el-col class="pd20" :span="2">备注</el-col>
      <el-col class="pd20 operate" :span="3">操作</el-col>
    </el-row>
    <div class="order" v-for="(item2, index2) in orderList" :key="index2">
      <el-row>
        <el-col class="info" :span="24">
          <span>订单号:{{ item2.number }}</span>
          <span v-if="item2.order_type !== 1"
            >订单类型:{{ setOrderType(item2.order_type) }}</span
          >
          <span v-if="item2.platform_order_id !== '0'">原始订单号：{{ item2.platform_order_id }}</span>

          <span
            >收货人:{{ item2.first_name }} {{ item2.country }}
            <i class="el-icon-tickets color" @click="infoShow(item2)"></i
          ></span>
        </el-col>
      </el-row>
      <el-row class="list" type="flex">
        <el-col class="detail" :span="8">
          <div
            class="df"
            v-for="(item3, index3) in item2.order_items"
            :key="index3"
          >
            <div class="list-left">
              <el-image
                style="width: 100%;height: 100%;"
                :src="item3.img ? item3.img : url"
                alt=""
                fit="scale-down"
              />
            </div>
            <div class="list-rigth">
              <div class="mb10" style="word-break:break-all;">
                {{ item3.name }}
              </div>
              <!--            <div class="mb10">SKU: {{item3.variant_sku}}</div>-->
              <div class="mb10">
                规格:
                {{ item3.options !== null ? item3.options.size : "暂无" }}/{{
                  item3.options !== null ? item3.options.color : "暂无"
                }}
              </div>
              <div class="mb10">
                <span class="pr20">单价:{{ item3.product_price }}</span
                ><span>数量：{{ item3.total_qty }}</span>
              </div>
            </div>
          </div>
        </el-col>

        <el-col class="money pd20" :span="4">
          <div>
            商品价: ¥{{
              !item2.discount_price ? " 暂无价格" : item2.discount_price
            }}
          </div>
          <div>运　费: ¥{{ item2.ship_price }}</div>
          <div>总　计: ¥{{ item2.total_price }}</div>
          <div>（共{{ item2.total_qty }}件）</div>
        </el-col>
        <el-col class="status pd20" :span="3">
          <div style="margin-top: 20px;margin-bottom: 20px">
            {{ item2.status_word }}
          </div>
          <div v-show="item2.is_sync2factory === 0">未同步工厂</div>
          <div v-show="item2.is_sync2factory === 1">已同步工厂</div>
          <div v-show="item2.is_sync2factory === 2">同步工厂失败</div>
        </el-col>
        <el-col class="time pd20" :span="4"
          >创建时间：{{ item2.created_at }}</el-col
        >
        <el-col :span="2" class="time" style="padding: 5px;">
        {{ item2.memo }}</el-col>
        <el-col class="operate pd20" :span="3">
          <div
            class="active"
            @click="pay(item2.number, item2.total_price)"
            v-if="item2.status === 0"
          >
            立即付款
          </div>
          <div
            @click="getOrderInfO(item2)"
            class="active"
            v-if="item2.status !== 0"
          >
            订单详情
          </div>
          <div @click="copy(item2.number, 1)">复制订单</div>
          <div
            @click="del(item2.number, 3)"
            v-if="item2.status === 5 || item2.status === 0"
          >
            删除订单
          </div>
          <div @click="cancel(item2.number, 2)" v-if="item2.status === 1">
            取消订单
          </div>
        </el-col>
      </el-row>
    </div>

    <div v-show="orderList.length <= 0" class="noOrder">暂无订单</div>
  </div>
</template>

<script>
import EditLogistics from "./EditLogistics.vue";
import EditProduct from "./EditProduct.vue";
import OrderDetails from "./OrderDetails.vue";
import recipentInfo from "./recipentInfo.vue";
import mr from "@/assets/icon/moren.png";
import {
  orderReceiveAddress,
  orderInfo,
  editOrderReceiveAddress,
  editOrderItem,
  deleteOrderItem,
} from "@/api/order";
import { getWalletBalance } from "@/api/costManagement";
import WarningPop from "./WarningPop.vue";
import OrderPay from "./OrderPay.vue";
import EditConsigneeInfo from "./editConsigneeInfo.vue";

export default {
  components: {
    recipentInfo,
    EditProduct,
    EditLogistics,
    OrderDetails,
    WarningPop,
    OrderPay,
    EditConsigneeInfo
},
  props: {
    orderList: {
      type: Array,
    },
  },
  data() {
    return {
      url: mr,
      value: null,
      // 搜索值
      searchValue: null,
      // 展示收件人信息
      InfoShow: false,
      // 待付款操作
      obligation: ["立即付款", "编辑产品", "编辑物流", "更多操作∨"],
      // 更多操作
      movetion: ["立即付款", "编辑产品", "编辑物流", "更多操作∨"],
      // 新订单操作
      Newtion: ["订单详情", "复制订单", "取消订单"],
      // 操作选中
      operateActive: 0,
      // 新订单操作选中
      newActive: 0,
      // 单独选中
      oneActive: 0,
      // 展示更多操作
      move: null,
      // 展示编辑产品
      editShow: false,
      // 展示编辑物流
      logisticsShow: false,
      // 展示详情
      detailsShow: false,
      // 订单id
      orderId: null,
      // 收货地址详细
      AddressInfo: {},
      // 订单详情
      OrderInfo: {},
      // 警告弹窗展示
      warningShow: false,
      // 警告区别id
      tabid: null,
      //订单号
      orderNumbers: null,
      // 支付金额
      amount: null,
      // 订单支付弹窗
      PayPopShow: false,
      // 余额
      balance: null,
      checkAll: false, //头部复选框
      checkedCities: [], //选择的商品
      isIndeterminate: false, //全选控制
      editConsigneeInfoStatus:false,
    };
  },
  created() {
    this.getWalletBalance();
  },
  mounted() {},
  methods: {

    updateOrderList() {
      this.editConsigneeInfoStatus = false
      this.$emit("update");
    },


    //支付完更新状态
    update() {
      this.$emit("update");
      this.getWalletBalance();
    },

    // 显示收件人信息
    infoShow(item) {
      this.AddressInfo = {...item};
      if (item.status === 0) {
        //未付款
        this.editConsigneeInfoStatus = true
      } else {
        this.InfoShow = true;
   
      }
    },
    info(val) {
      this.AddressInfo = {};
      this.InfoShow = val;
    },
    // 搜索
    Search() {
      this.$emit("keyword", this.searchValue);
    },
    // 余额查询
    async getWalletBalance() {
      try {
        const { data } = await getWalletBalance();
        this.balance = data.balance;
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 编辑产品操作
    EditShow(id) {
      this.editShow = true;
      this.orderId = id;
      this.orderInfo();
    },
    // 编辑物流
    LogisticsShow(id) {
      this.logisticsShow = true;
    },
    // 复制订单
    copy(id, tabId) {
      this.warningShow = true;
      this.tabid = tabId;
      this.orderId = id;
      // this.$emit('copy',id)
    },
    // 取消订单
    cancel(id, tabId) {
      this.warningShow = true;
      this.tabid = tabId;
      this.orderId = id;
    },
    // 订单详情
    getOrderInfO(item) {
      this.detailsShow = true;
      this.orderInfo(item);
    },
    // 删除订单
    del(id, tabId) {
      this.warningShow = true;
      this.tabid = tabId;
      this.orderId = id;
    },
    // 关闭警告弹窗
    warnClose(val) {
      this.warningShow = val;
    },
    // 确认警告弹窗
    warnSure(val) {
      this.warningShow = val;
      if (this.tabid === 1) {
        this.$emit("copy", this.orderId);
      } else if (this.tabid === 2) {
        this.$emit("cancel", this.orderId);
      } else if (this.tabid === 3) {
        this.$emit("del", this.orderId);
      }
    },
    // 立即支付
    pay(val, m) {
      this.orderNumbers = val;
      this.amount = m;
      this.PayPopShow = true;
    },
    // 编辑窗支付
    editPay(val, m) {
      this.orderNumbers = val;
      this.amount = m;
      this.PayPopShow = true;
    },
    // 关闭弹窗
    payShow(val) {
      this.PayPopShow = val;
      // location.reload();
    },
    // 编辑产品关闭
    Edit(val) {
      this.editShow = val;
      this.$emit("renew");
    },
    // 编辑物流关闭
    Logistics(val) {
      this.logisticsShow = val;
    },
    // 订单详情
    Details(val) {
      this.detailsShow = val;
    },
    // 修改订单信息
    async Modification(id, code, num, oid) {
      this.orderId = oid;
      let itemid = id + "";

      try {
        const { msg } = await editOrderItem({
          orderId: this.orderId,
          orderItemId: itemid,
          code: code,
          amount: num,
        });
        this.$message.success(msg);
        await this.orderInfo();
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 删除订单商品
    async DeletOItem(code, id) {
      try {
        const { data, msg } = await deleteOrderItem({
          orderId: id,
          itemCode: code,
        });
        this.$message({
          message: msg,
          type: "success",
        });
        await this.orderInfo();
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 收货地址详细
    async orderReceiveAddress() {
      try {
        const { data } = await orderReceiveAddress({
          orderId: this.orderId,
        });
        this.AddressInfo = data.info;
      } catch (e) {
        this.$message.error(e);
      }
    },

    // 订单详情
    async orderInfo(item) {
      try {
        const { data } = await orderInfo({
          orderNumber: item.number,
        });
        this.OrderInfo = data;
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 修改订单地址
    async ReceiveAddress(id, val) {
      try {
        await editOrderReceiveAddress({
          orderId: id,
          last_name: val.name,
          phone: val.phone,
          zip: val.code,
          country: val.country,
          state: val.state,
          city: val.city,
          address1: val.address,
        });
        await this.orderInfo();
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 全选框
    handleCheckAllChange(val) {
      let arr = [];
      this.orderList.forEach((e) => {
        arr.push(e.id);
      });
      this.checkedCities = val ? arr : [];
      this.isIndeterminate = false;
      this.sum = this.checkedCities.length;
    },
    // 单选
    handleCheckedCitiesChange(val) {
      let arr = [];
      this.orderList.forEach((e) => {
        arr.push(e.id);
      });
      this.checkAll = val.length === arr.length;
      this.isIndeterminate = val.length > 0 && val.length < arr.length;
      this.sum = this.checkedCities.length;
    },
  },
  computed: {
    setOrderType() {
      return (val) => {
        // 订单类型:1正常单2加急单3因工厂或平台导致的返工单
        if (Number(val) === 2) {
          return "加急单";
        } else if (Number(val) === 3) {
          return "因工厂或平台导致的返工单";
        }
      };
    },
  },
};
</script>

<style scoped lang="less">
.noOrder {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
}
.color {
  color: #fa4a14;
}

.active {
  color: #fa4a14;
}

.pd20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.df {
  display: flex;
  padding-bottom: 15px;
  // border-bottom: 1px solid #d8d8d8;
  padding-right: 50px;
}

.el-dropdown-link {
  cursor: pointer;
  font-size: 12px;
  color: #333;
}

.el-dropdown-menu__item {
  font-size: 12px;
  color: #333;
  line-height: 24px;
  padding: 0 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

/deep/ .el-checkbox__label {
  display: none;
}

.order-column {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  .column-left {
    .Allcheck {
      margin-right: 10px;
    }

    .bulkOper {
      width: 100px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      margin-left: 20px;
      border: 1px solid #d3d3d3;
    }

    .export {
      width: 71px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      margin-right: 20px;
      border: 1px solid #d3d3d3;
    }

    /deep/ .el-input--mini {
      .el-input__inner {
        height: 26px;
        line-height: 26px;
        padding: 0 0 0 5px;
        border: 0;
      }
    }
  }

  .column-rigth {
    width: 412px;
    height: 30px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #999999;

    .searchbtn {
      // background: #fff;
      height: 28px;
    }

    /deep/ .el-input-group--append {
      .el-input__inner {
        border: 0;
      }
    }

    /deep/ .el-input-group__append {
      background: #fff;
      border: 0;
      color: #333;
    }
  }
}

.order-body {
  padding: 10px 20px;
  background: #f7f7f7;
  font-size: 15px;
  font-weight: 600;
  color: #333333;

  .operate {
    text-align: center;
  }
}

.order {
  min-width: 1000px;
  overflow: auto;
  margin-top: 13px;
  border: 1px solid #d8d8d8;

  .info {
    background: #f7f7f7;
    padding: 20px;
    font-size: 14px;
    color: #666666;
    display: flex;

    .Allcheck {
      margin-right: 10px;
    }

    span {
      margin-right: 40px;
    }
  }

  .list {
    padding: 20px;
    font-size: 12px;

    .detail {
      display: flex;
      flex-wrap: wrap;
      border-right: 1px solid #d8d8d8;

      .list-left {
        width: 102px;
        height: 102px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list-rigth {
        font-size: 12px;

        .mb10 {
          margin-bottom: 10px;
        }

        .color-box {
          vertical-align: text-bottom;
          display: inline-block;
          margin-left: 4px;
          width: 14px;
          height: 14px;
          border: 1px solid #d8d8d8;
        }
      }
    }

    .money {
      border-right: 1px solid #d8d8d8;

      div {
        margin-bottom: 10px;
      }
    }

    .logistics {
      border-right: 1px solid #d8d8d8;
    }

    .operate {
      text-align: center;
      cursor: pointer;

      div {
        margin-bottom: 10px;
      }
    }

    .status {
      border-right: 1px solid #d8d8d8;
    }

    .time {
      border-right: 1px solid #d8d8d8;
    }
  }
}

.exportButton {
  background: #fa4a14;
  color: #fff;
  margin-left: 20px;
}

.el-icon-tickets {
  cursor: pointer;
}
</style>
